@import '../../styles/variables';

.main {
}

.mainBackground {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../../public/images/main-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -5;
  }

  @media (max-width: $mobileMax) {
    &::before {
      background-image: url('../../../public/images/main-background-mobile.svg');
    }
  }
}

.tickerContainer {
  background: linear-gradient(101.11deg, rgba(60, 245, 255, 0.82) -95.5%, rgba(3, 140, 255, 0.82) 30.33%);
  max-height: 100px;
  height: 10vw;

  @media (max-width: $mobileMax) {
    min-height: 39px;
  }
}

.ticker {
  background-image: url('../../../public/images/ticker.png');
  background-repeat: repeat-x;
  background-size: auto 70%;
  height: 100%;
  background-position: center;
}
