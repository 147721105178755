@import '../../styles/variables';

.footer {
  width: 100%;
  height: 35px;
  background: rgba(46, 157, 255, 1);

  @media (max-width: $mobileMax) {
    height: 50px;
  }
}

.footerBg {
  width: 100%;
  height: 100%;
  background: rgba(46, 157, 255, 1);
  border-radius: 0px;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 56px;

  @media (max-width: $mobileMax) {
    flex-direction: column;
    justify-content: center;
    padding: 0 28px;
  }

  @media (max-width: $tabletMin) {
    background: rgba(0, 135, 255, 1);
  }
}

.footerText {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  @media (max-width: $mobileMax) {
    font-size: 9.54px;
  }
}

.footerSocialsList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footerSocialsListItem {
  width: 20px;
  height: 20px;
  &:not(:last-child) {
    margin-right: 17px;
    @media (max-width: $mobileMax) {
      margin-right: 13px;
    }
  }
}

.footerSocialsItemLink {
  width: 100%;
  height: 100%;
  display: block;
  background-size: contain;
}

.twitter {
  background: url('../../../public/images/s_twitter.svg');
  background-repeat: no-repeat;

  &:hover {
    background: url('../../../public/images/s_twitter-hover.svg');
    background-repeat: no-repeat;
  }
}

.telegram {
  background: url('../../../public/images/s_telegram.svg');
  background-repeat: no-repeat;

  &:hover {
    background: url('../../../public/images/s_telegram-hover.svg');
    background-repeat: no-repeat;
  }
}

.discord {
  background: url('../../../public/images/s_discord.svg');
  background-repeat: no-repeat;

  &:hover {
    background: url('../../../public/images/s_discord-hover.svg');
    background-repeat: no-repeat;
  }
}
