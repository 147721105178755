@import '../../../styles/variables';

.servicesSection {
  padding-top: 50px;

  @media (max-width: $tabletMin) {
    padding-top: 40px;
  }
}

.servicesContainer {
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 28px;

  @media (min-width: $tabletMin) {
    padding: 0 56px;
  }
}

.servicesText {
  display: flex;
  justify-content: left;
  padding-bottom: 27px;
  padding-left: 56px;

  @media (max-width: $tabletMin) {
    justify-content: center;
    padding-bottom: 16px;
    padding-left: 0;
  }
}

.servicesBlocks {
  display: flex;
  justify-content: space-between;

  @media (max-width: $tabletMin) {
    flex-direction: column;
    align-items: center;
  }
}

.servicesItem {
  position: relative;
  width: 44vw;
  max-width: 448px;
  height: 41vw;
  max-height: 420px;
  backdrop-filter: blur(100px);
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media (max-width: $tabletMin) {
    width: 84vw;
    min-width: 265px;
    max-width: 550px;
    height: 59vw;
    min-width: 191px;
    max-height: 420px;
  }
}

.players {
  background: linear-gradient(30.08deg, rgba(255, 255, 255, 0.06) -3.27%, rgba(255, 255, 255, 0) 105.22%);
  margin-right: 14px;

  @media (max-width: $tabletMin) {
    margin-right: 0;
    margin-bottom: 20px;
    backdrop-filter: blur(45.5768px);
    border-radius: 10px;
  }
}

.studios {
  background: linear-gradient(208.33deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 103.96%);

  @media (max-width: $tabletMin) {
    backdrop-filter: blur(45.5768px);
    border-radius: 10px;
  }
}

.servicesHeaders {
  padding-bottom: 20px;
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: #25aaf7;

  @media (max-width: $tabletMax) {
    padding-bottom: 10px;
  }

  @media (max-width: $tabletMin) {
    padding-bottom: 1.8vw;
    font-size: 4.5vw;
    text-align: center;
  }
}

.background {
  padding: 32px 56px 0 56px;

  @media (max-width: $tabletMax) {
    padding: 10px 13px 0 10px;
  }
}

.playersBackground {
  width: 100%;
  height: 100%;
  background-image: url('../../../../public/images/players-bg.png');
  background-size: 185%;
  background-position: -180px 140px;
  background-repeat: no-repeat;
  border-radius: 20px;

  @media (max-width: $tabletMin) {
    background-size: 135%;
    background-position: -10vw 30vw;
    border-radius: 10px;
  }
}

.studiosBackground {
  width: 100%;
  height: 100%;
  background-image: url('../../../../public/images/studios-bg.png');
  background-size: 120%;
  background-position: -40px 150px;
  background-repeat: no-repeat;
  border-radius: 20px;

  @media (max-width: $tabletMin) {
    background-image: url('../../../../public/images/studios-bg-mobile.png');
    background-position: -8vw 20vw;
    border-radius: 10px;
  }
}

.list {
  padding-left: 28px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #3c4963;
  @media (max-width: $tabletMin) {
    font-size: 3.1vw;
  }

  & > li {
    list-style-type: disc;
  }
}
