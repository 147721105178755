@import '../../../styles/variables';

.teamSection {
  margin-bottom: 68px;
  padding-top: 50px;

  @media (max-width: $mobileMax) {
    margin-bottom: 50px;
  }
}

.teamContainer {
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $laptopMin) {
    padding: 0 56px;
  }
}

.teamText {
  padding-bottom: 28px;

  @media (max-width: $tabletMin) {
    justify-content: center;
    padding-top: 6.2vw;
    padding-bottom: 20px;
  }
}

.teamItemContainer {
  height: 388px;
}

.teamItem {
  width: 205px;
  height: 373px;
  margin: 0 auto;
  background: rgba(0, 135, 255, 0.77);
  box-shadow: 0px 3.53554px 3.53554px rgba(0, 0, 0, 0.34);
  border-radius: 88.3886px 88.3886px 8.83886px 8.83886px;
  color: #ffffff;
}

.photo {
  display: flex;
  justify-content: center;
}

.photoImg {
  margin-top: 11px;
  margin-top: 13px;
}

.name {
  font-family: 'Archivo Black';
  font-style: normal;
  font-weight: 400;
  font-size: 14.1422px;
  line-height: 15px;
  text-align: center;
}

.social {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.descriptionContainer {
  margin: 0 12px 12px 12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 9.72275px;
  line-height: 13px;
}

.description {
  li {
    list-style-type: disc;
    list-style-position: inside;

    &:first-child {
      list-style-type: none;
    }
  }
}

ul.dotsList {
  li button {
    position: relative;
    width: 11px;
    height: 11px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 11px;
      height: 11px;
      border-radius: 5px;
      background: linear-gradient(180deg, #0087ff 0%, #34f7ff 100%);
      opacity: 1;
    }
  }

  li[class='slick-active'] {
    & button {
      &:before {
        width: 40px;
      }
    }

    &:last-child button {
      &:before {
        left: -29px;
      }
    }
  }

  &.forward-1 {
    li[class='slick-active'] {
      button {
        &:before {
          left: -29px;
        }
      }
    }
  }

  &.forward-2 {
    li[class='slick-active'] {
      button {
        &:before {
          left: -29px;
        }
      }
    }
  }
  &.forward-3 {
    li[class='slick-active'] {
      button {
        &:before {
          left: -29px;
        }
      }
    }
  }

  @media (min-width: $tabletMax) {
    display: none;
  }
}
