* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  height: 100hv;
}

input,
button,
textarea {
  font-family: inherit;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;

  &:visited {
    color: initial;
  }

  &:visited,
  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

ul li {
  list-style: none;
}
