@import '../../styles/variables';

.subscribePageSection {
  position: relative;
  background-image: url('../../../public/images/bg-subscribe_1.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  min-height: 100vh;

  @media (min-width: $laptopMin) {
    background-image: url('../../../public/images/bg-subscribe_2.png');
  }
}

.subscribePageContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 47px 67px;

  @media (max-width: $tabletMax) {
    padding: 0 18px 55px;
  }
}

.subscribePageHeader {
  height: 76px;
  width: 100%;
  padding: 24px 0 12px 0;

  @media (max-width: $tabletMax) {
    height: 114px;
    padding: 40px 0 32px 0;
    display: flex;
    justify-content: center;
  }
}

.logoImg {
  height: 40px;
}

.infoFormContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: calc(100vh - 76px - 76px);

  @media (max-width: $tabletMax) {
    justify-content: center;
  }
}

.infoFormWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 270px;
  max-height: calc(100vh - 76px - 76px);

  @media (max-width: $tabletMax) {
    flex-direction: column-reverse;
    max-height: 3000px;
  }
}

.formContainer {
  width: 38.7%;

  @media (max-width: $tabletMax) {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
  }
}

.form {
  display: flex;
  width: 283px;
  flex-direction: column;
  @media (max-width: $tabletMin) {
    width: 100%;
  }
}

.input {
  display: block;
  background-color: transparent;
  border-radius: none;
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 12.6175px;

  position: relative;
  border-radius: 0;

  &::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12.6175px;
    line-height: 17px;
    color: #ffffff;
  }
}

.inputName {
  height: 35px;
}

.inputEmail {
  height: 35px;
}

.checkboxWrapper {
  display: flex;
  padding-top: 12px;
  padding-bottom: 23px;
}

.checkbox {
  opacity: 0;
  width: 0;
}

.checkboxLabel {
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 23.52px;
    height: 24.14px;
    border: 0.965759px solid #ffffff;
    border-radius: 3.77576px;
  }
}

.checkbox:checked + label::after {
  content: '';
  position: absolute;
  left: 1px;
  top: 4px;
  width: 23.52px;
  height: 24.14px;
  background-image: url('../../../public/images/mark.svg');
  background-repeat: no-repeat;
}

.checkboxInfo {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 9.29963px;
  line-height: 11px;
  color: #ffffff;
  opacity: 0.89;

  & > a {
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.7);
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 9.29963px;
    cursor: pointer;

    &:hover,
    &:visited,
    &:active,
    &:focus {
      text-decoration: underline;
      text-decoration-color: rgba(255, 255, 255, 0.7);
      color: #ffffff;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 9.29963px;
    }
  }
}

.buttonWrapper {
  margin-top: 0;
  display: flex;
  justify-content: start;
  @media (max-width: $tabletMax) {
    justify-content: center;
  }
}

.button {
  width: 110px;
  height: 35px;
  background: linear-gradient(93.7deg, #0087ff 2.09%, #34f7ff 100%);
  border-radius: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);

  &:disabled {
    opacity: 0.7;
    cursor: auto;
  }

  @media (max-width: $tabletMin) {
    border-radius: 21.3889px;
    width: 25.5vw;
    height: 8vw;
    max-width: 125px;
    max-height: 50px;
    min-width: 100px;
    min-height: 30px;
    font-size: 15.6px;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

.infoWrapper {
  width: 61.3%;
  display: flex;
  justify-content: space-between;

  @media (max-width: $tabletMax) {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }

  @media (max-width: $tabletMin) {
    flex-direction: column;
    max-width: 400px;
    align-items: center;
    padding: 0 17px;
    margin-bottom: 0px;
  }
}

.infoItemWrapper {
  width: 270px;
  min-height: 331px;
  padding: 14px 14px;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 17.953px;
  color: #ffffff;

  @media (max-width: $tabletMax) {
    &:first-child {
      margin-right: 20px;
    }
  }

  @media (max-width: $tabletMin) {
    margin-bottom: 19px;
    width: 100%;
    min-height: 0;
    padding-bottom: 28px;

    &:last-child {
      margin-bottom: 30px;
    }
    &:first-child {
      margin-right: 0px;
    }
  }
}

.descriptionHeader {
  font-family: 'Circo';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  min-height: 54px;
  padding-bottom: 10px;
}

.description {
  padding-left: 14px;
  li {
    list-style-type: disc;
    list-style-position: outside;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 9.6703px;
    line-height: 124.5%;
    letter-spacing: 0.055em;

    @media (max-width: $tabletMin) {
      font-size: 9px;
    }
  }
}
