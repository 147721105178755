@import '../../../styles/variables';

.aboutSection {
  // margin-bottom: 50px;
}

.aboutContainer {
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 28px;

  @media (max-width: $pageContainerWidth) {
    width: $pageContainerWidth;
  }

  @media (min-width: $tabletMin) {
    display: flex;
    padding: 0 56px;
  }
}

.aboutText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $xl) {
    width: 40%;
  }
  margin-left: calc((100vw - 1024px) / 2);

  @media (max-width: $pageContainerWidth) {
    margin-left: 0;
  }
  @media (max-width: $mobileMax) {
    width: 100%;
    padding: 25px 0 0 0;
  }
}

.aboutDescription {
  max-width: 320px;
  margin-top: 12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-align: justify;
  color: #323d53;

  @media (max-width: $mobileMax) {
    width: 64vw;
    max-width: 100%;

    margin-top: 12px;
    font-size: 3.2vw;
  }
}

.aboutSteps {
  padding-top: 70px;

  @media (min-width: $tabletMin) {
    width: 60%;
  }
}

.stepContainer {
  position: relative;
  height: 129px;

  &:not(:last-child) {
    margin-bottom: 50px;

    @media (max-width: $mobileMax) {
      margin-bottom: 3vw;
    }
  }

  &:nth-child(even) > .step {
    width: 51vw;

    @media (max-width: $pageContainerWidth) {
      max-width: 525px;
    }

    @media (max-width: $mobileMax) {
      left: -28px;
      transform: scaleX(-1);
    }

    .stepTitle {
      @media (max-width: $mobileMax) {
        text-align: right;
        transform: scaleX(-1);
      }
    }
  }

  @media (max-width: $mobileMax) {
    height: 20vw;
    max-height: 129px;
  }
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: end;
  -webkit-justify-content: flex-end;
  position: absolute;
  right: -56px;
  height: 100%;
  width: 40vw;
  box-shadow: (0px 4px 4px #9069ff);
  border-radius: 21.808px 0px 0px 21.808px;
  clip-path: inset(-60px 0px -5px -5px);

  @media (max-width: $pageContainerWidth) {
    max-width: 415px;
  }

  @media (max-width: $mobileMax) {
    right: -28px;
    width: 51vw;
    max-width: 525px;
    background-size: cover;
  }

  &::before {
    content: '';
    display: block;
    width: 118px;
    height: 118px;
    position: absolute;
    top: -50px;
    left: 20%;
    background-position: center;
    background-size: cover;
    z-index: 2;

    @media (max-width: $mobileMax) {
      top: -70px;
      width: 27vw;
      height: 27vw;
      max-width: 118px;
      max-height: 118px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 150px;
    height: 75px;
    border-radius: 0 0 112px 112px;
    position: absolute;
    top: 0;

    @media (max-width: $mobileMax) {
      width: 120px;
      height: 60px;
      border-radius: 0 0 80px 80px;
    }
  }
}

.buyStep {
  background: linear-gradient(96.07deg, rgba(255, 255, 255, 0.3) 1.1%, rgba(255, 255, 255, 0) 100%);

  &::before {
    background-image: url('../../../../public/images/wallet.png');
    border-radius: 100%;
  }

  &::after {
    left: 15%;
    filter: blur(15px);
    background: radial-gradient(circle, rgba(52, 247, 255, 0.5) 60%, rgba(255, 255, 255, 0) 100%);
  }
}

.playStep {
  background: linear-gradient(93.62deg, rgba(255, 255, 255, 0.29) -2.63%, rgba(255, 255, 255, 0) 100%);

  &::before {
    background-image: url('../../../../public/images/cube.png');

    @media (max-width: $mobileMax) {
      transform: scaleX(-1);
    }
  }

  &::after {
    left: 12%;
    filter: blur(20px);
    background: radial-gradient(circle, rgba(37, 170, 246, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    @media (max-width: $mobileMax) {
      left: 40%;
    }
  }
}

.joinStep {
  background: linear-gradient(93.62deg, rgba(255, 255, 255, 0.29) -2.63%, rgba(255, 255, 255, 0) 100%);

  &::before {
    top: -75px;
    background-image: url('../../../../public/images/rocket.png');
  }

  &::after {
    left: 12%;
    filter: blur(22px);
    background: radial-gradient(circle, rgba(222, 143, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  }
}

.stepTitle {
  margin-bottom: 15px;
  padding-left: 80px;
  padding-right: 28px;
  font-family: 'Archivo Black';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 120%;
  color: #00a2ff;

  @media (max-width: $mobileMax) {
    padding-left: 10vw;
    font-size: 3.3vw;
  }
}
