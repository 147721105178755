@import '../../styles/variables';

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(25px);
  z-index: 100;

  @media (max-width: $mobileMax) {
    height: 40px;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 56px;

  @media (max-width: $mobileMax) {
    padding: 0 28px;
  }
}

.logoImg {
  @media (max-width: $mobileMax) {
    width: 71px;
  }
}

.headerNav {
  @media (max-width: $mobileMax) {
    position: fixed;
    top: 40px;
    left: 100%;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 40px);
    padding-top: 20px;
    background: #0087ff;
    transition: left 0.3s;
    overflow: auto;
  }

  &.openedMenu {
    left: 0;
  }
}

.navList {
  display: flex;
  align-items: center;

  @media (max-width: $mobileMax) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.navListItem {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  &:not(:last-child) {
    margin-right: 2px;
  }

  &:last-child {
    background: linear-gradient(93.7deg, #0087ff 2.09%, #34f7ff 100%);
    border-radius: 20px;

    &:hover {
      background: transparent;
    }

    @media (max-width: $mobileMax) {
      margin-bottom: 50px;
    }
  }

  @media (max-width: $mobileMax) {
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 6vw;
    line-height: 8.4vw;
    font-family: Open Sans;
    margin-right: 0px;

    &:not(:last-child) {
      margin-right: 0px;
    }

    &:last-child {
      font-weight: 700;
      background: transparent;
      border-radius: none;
      padding: 0;
    }

    &:first-child {
      padding-top: 6px;
    }
  }
}

.navItemLink {
  position: relative;
  height: 40px;
  width: 111px;

  display: inline-block;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 2px;
    height: 38px;
    width: 111px;
    background-image: url('../../../public/images/hover.svg');
    background-size: contain;
    background-repeat: no-repeat;
    visibility: hidden;
  }

  &:visited {
    color: #ffffff;
  }

  &:hover {
    &::after {
      visibility: visible;
    }
  }

  @media (max-width: $mobileMax) {
    width: 100%;
    height: 100%;

    &:hover {
      &::after {
        visibility: hidden;
      }
    }
  }
}

.burgerButton {
  display: none;

  @media (max-width: $mobileMax) {
    display: block;
    z-index: 110;
    background-color: transparent;
  }
}

.burgerIcon {
  position: relative;
  width: 32px;
  height: 15px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    position: absolute;
    top: 0;
    background-color: #f2f2f2;
    border-radius: 2px;
    z-index: 115;
    transition: transform 0.3s;
  }

  &::after {
    content: '';
    display: block;
    width: 66%;
    height: 3px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #f2f2f2;
    border-radius: 2px;
    z-index: 115;
    transition: transform 0.3s, width 0.3s;
  }

  &.openedMenu {
    &::before {
      transform: rotate(45deg) translate(3px, 4px);
    }

    &::after {
      width: 100%;
      transform: rotate(-45deg) translateY(-10px);
    }
  }
}

.disableScroll {
  overflow: hidden;
}
