@import '../../../styles/variables';

.contactsSection {
  background-image: url('../../../../public/images/contacts-background_1.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-position: center;

  @media (min-width: $tabletMin) {
    background-image: url('../../../../public/images/contacts-background.png');
  }
}

.contactsContainer {
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $tabletMin) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 56px;
  }
}

.contactsText {
  padding-top: 30px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: $mobileMax) {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    padding-top: 15px;
  }
}

.sectionTitle {
  font-family: 'Circo';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  padding-left: 12px;

  @media (max-width: $mobileMax) {
    font-size: 7.5vw;
    padding-left: 0px;
  }
}

.formContainer {
  width: 50%;
  padding: 0 12px;
  @media (max-width: $tabletMin) {
    width: 100%;
    padding: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  display: block;
  background-color: transparent;
  border-radius: none;
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 14px;
  position: relative;
  border-radius: 0;

  &::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
}

.inputName {
  height: 35px;
  @media (max-width: $tabletMin) {
    height: 8.4vw;
  }
}

.inputEmail {
  height: 35px;
  @media (max-width: $tabletMin) {
    height: 8.1vw;
  }
}

.inputMessage {
  height: 35px;

  @media (max-width: $tabletMin) {
    height: 7.1vw;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: start;
  @media (max-width: $tabletMax) {
    justify-content: center;
  }
}

.button {
  width: 110px;
  height: 35px;
  background: linear-gradient(93.7deg, #0087ff 2.09%, #34f7ff 100%);
  border-radius: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin: 19px 0;

  @media (max-width: $tabletMin) {
    height: 4.4vw;
    border-radius: 21.3889px;
    width: 24vw;
    height: 7.3vw;
    font-size: 3vw;
    margin: 14px 0;
  }
}

textarea:focus,
input:focus {
  outline: none;
}
