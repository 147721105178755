@import '../../../styles/variables';

.bannerSection {
  position: relative;
  height: 62vw;
  max-height: 638px;
  background-image: url('../../../../public/images/banner_1.png');
  background-repeat: no-repeat;
  // background-position: bottom;
  background-position: center;
  background-size: 100% auto;

  @media (min-width: $laptopMin) {
    background-image: url('../../../../public/images/banner_2.png');
  }
}

.bannerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 56px;

  @media (max-width: $mobileMax) {
    padding: 0 28px;
  }
}

.bannerHeadingWrapper {
  background-image: url('../../../../public/images/header.png');
  background-repeat: no-repeat;
  max-width: 196px;
  max-height: 305px;
  width: 19.14vw;
  height: 29.8vw;
  background-size: contain;
  margin-bottom: 38px;
  margin-top: 38px;

  @media (max-width: $mobileMax) {
    width: 14.6vw;
    height: 22.6vw;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.bannerOpenButtonWrapper {
  @media (max-width: $mobileMax) {
    display: none;
  }
}

.bannerOpenButton {
  width: 194px;
  height: 35px;
  background: linear-gradient(93.7deg, #0087ff 2.09%, #34f7ff 100%);
  border-radius: 20px;
}

.bannerOpenLink {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;

  &:visited,
  &:hover,
  &:focus {
    color: #ffffff;
  }
}

.bannerSocialsList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: 50%;
  right: 0;
  height: 160px;
  width: 40px;
  padding: 40px 8px;
  transform: translateY(-50%);
  background: rgba(0, 135, 255, 0.52);
  backdrop-filter: blur(10px);
  border-radius: 30px 0px 0px 30px;
  border-style: solid;
  border-image-source: linear-gradient(1.08deg, rgba(255, 255, 255, 0.5) -7.56%, rgba(255, 255, 255, 0) 100%);

  @media (max-width: $mobileMax) {
    display: none;
  }
}

.bannerSocialsListItem {
  width: 20px;
  height: 20px;
}

.bannerSocialsItemLink {
  width: 100%;
  height: 100%;
  display: block;
  background-size: contain;
}

.twitter {
  background: url('../../../../public/images/s_twitter.svg');
  background-repeat: no-repeat;

  &:hover {
    background: url('../../../../public/images/s_twitter-hover.svg');
    background-repeat: no-repeat;
  }
}

.telegram {
  background: url('../../../../public/images/s_telegram.svg');
  background-repeat: no-repeat;

  &:hover {
    background: url('../../../../public/images/s_telegram-hover.svg');
    background-repeat: no-repeat;
  }
}

.discord {
  background: url('../../../../public/images/s_discord.svg');
  background-repeat: no-repeat;

  &:hover {
    background: url('../../../../public/images/s_discord-hover.svg');
    background-repeat: no-repeat;
  }
}
