@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

@font-face {
  font-family: 'Circo';
  src: url('fonts/circo-webfont.eot');
  src: url('fonts/circo-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/circo-webfont.woff2') format('woff2'), url('fonts/circo-webfont.woff') format('woff'),
    url('fonts/circo-webfont.svg#circoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import './styles/all.scss';
