@import '../../../styles/variables';

.roadmapSection {
  padding-top: 50px;

  @media (max-width: $tabletMin) {
    // padding-top: 40px;
  }
}

.roadmapContainer {
  width: $pageContainerWidth;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 56px;
}

.roadmapPoints {
  position: relative;
  padding-top: 67px;
  padding-bottom: 50px;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: $mobileMax) {
    padding-top: 20px;
  }

  @media (min-width: $tabletMin) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 165px;
      left: 50%;
      transform: translateX(-50%);
      width: 7px;
      height: 443px;
      border: 2px solid #0087ff;
      background: linear-gradient(180deg, #0087ff 0%, #34f7ff 100%);
    }
  }
}

.roadmapPointContainer {
  display: flex;
  justify-content: center;
  position: relative;
  height: 106px;

  &:not(:last-child) {
    margin-bottom: 72px;

    @media (max-width: $mobileMax) {
      margin-bottom: 32px;
    }
  }

  &:nth-child(even) {
    .roadmapPointDescription {
      left: 0;

      &::before {
        right: -80px;
      }
    }
  }

  &:nth-child(odd) {
    .roadmapPointDescription {
      right: 0;

      &::before {
        left: -80px;
      }
    }
  }

  @media (max-width: $mobileMax) {
    flex-direction: column;
    align-items: center;
    height: max-content;
  }
}

.roadmapPoint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 20vw;
  max-width: 230px;
  padding: 16px 0;
  background: #ffffff;
  box-shadow: 0px 4px 5px #0087ff;
  border-radius: 20px;
  z-index: 5;

  @media (max-width: $mobileMax) {
    width: 60vw;
    margin-bottom: 21px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -21px;
      left: 30px;
      height: 21px;
      width: 3px;
      background-color: #0087ff;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -21px;
      right: 30px;
      height: 21px;
      width: 3px;
      background-color: #0087ff;
    }
  }
}

.pointIcon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 8px;

  &.first {
    background-image: url('../../../../public/images/i-rocket.svg');
  }

  &.second {
    background-image: url('../../../../public/images/i-lightning.svg');
  }

  &.third {
    background-image: url('../../../../public/images/i-circle.svg');
  }

  &.forth {
    background-image: url('../../../../public/images/i-atom.svg');
  }
}

.pointTitle {
  font-family: 'Archivo Black';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #0087ff;
}

.roadmapPointDescription {
  width: 60vw;
  max-width: 230px;

  @media (min-width: $tabletMin) {
    position: absolute;
    top: 50%;
    width: 227px;
    transform: translateY(-50%);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 80px;
      height: 3px;
      background-color: #0087ff;
    }
  }
}

.descriptionList {
  position: relative;
  padding: 23px 6px;
  padding-left: 24px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.15);

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 16px;
    bottom: -16px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-size: cover;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.15);
    z-index: -1;
  }

  &.first {
    &::before {
      background-image: url('../../../../public/images/bg_point-1.svg');
    }
  }

  &.second {
    &::before {
      background-image: url('../../../../public/images/bg_point-2.svg');
    }
  }

  &.third {
    &::before {
      background-image: url('../../../../public/images/bg_point-3.svg');
    }
  }

  &.forth {
    &::before {
      background-image: url('../../../../public/images/bg_point-4.svg');
    }
  }

  & > li {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #0087ff;
    list-style-type: disc;
  }
}
