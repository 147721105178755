@import '../../../styles/variables';

.sectionTitle {
  font-family: 'Circo';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 120%;
  /* identical to box height, or 77px */
  text-align: center;

  background: linear-gradient(93.7deg, #0087ff 2.09%, #34f7ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: $mobileMax) {
    font-size: 7.5vw;
  }
}
